// @ts-nocheck
import styled from 'styled-components';
import { motion } from 'framer-motion';
import useDynamicText from '../../hooks/useDynamicText';
import useFitText from 'use-fit-text';

const Container = styled<any>(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  color: ${({ color }) => color};
  line-height: 1.2;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  p {
    width: 100%;
    margin-bottom: 12px;
  }
`;

type Props = {
  text?: string;
  color?: string;
};

const Text = ({ text, color }: Props) => {
  const t = useDynamicText();
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  };

  const transition = { duration: 1, type: 'tween' };

  const { fontSize, ref } = useFitText({ maxFontSize: 500 });

  return (
    <Container
      ref={ref}
      color={color}
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={transition}
      style={{ fontSize }}
      dangerouslySetInnerHTML={{
        __html: t(text)
      }}
    ></Container>
  );
};

export default Text;
