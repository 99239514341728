// @ts-nocheck

import { useEffect, useState } from 'react';

const createLink = (fonts, subsets, display) => {
  let families = fonts
    .reduce((acc, font) => {
      const family = font?.font?.replace(/ +/g, '+');
      const weights = (font.weights || []).join(',');

      return [...acc, family + (weights && `:${weights}`)];
    }, [])
    .join('|');

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = `https://fonts.googleapis.com/css?family=${families}`;
  link.crossOrigin = 'anonymous';

  if (subsets && Array.isArray(subsets) && subsets.length > 0) {
    link.href += `&subset=${subsets.join(',')}`;
  }

  if (display) {
    link.href += `&display=${display}`;
  }

  return link;
};

const uniqueFonts = (fonts) => {
  const seen = new Set();

  return fonts.filter((font) => {
    if (!font || !font.font || seen.has(font.font)) {
      return false;
    }

    seen.add(font.font);
    return true;
  });
};

const GoogleFontLoader = ({ fonts, subsets = null, display = null }) => {
  const filteredFonts = uniqueFonts(fonts);
  const [link, setLink] = useState(createLink(filteredFonts, subsets, display));

  useEffect(() => {
    document.head.appendChild(link);

    return () => document.head.removeChild(link);
  }, [link]);

  useEffect(() => {
    setLink(createLink(uniqueFonts(fonts), subsets, display));
  }, [fonts, subsets, display]);

  return null;
};

export default GoogleFontLoader;
