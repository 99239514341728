// @ts-nocheck
import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { IMaskInput } from 'react-imask';
import { Grid } from 'react-loader-spinner';
import { ProjectContext } from '../../ProjectContext';
import { FormDataType } from '../../types';
// import useDynamicText from '../../hooks/useDynamicText';
import useFitText from 'use-fit-text';

const Container = styled<any>(motion.div)`
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => color};
  input,
  select {
    color: ${({ fieldTextColor }) => fieldTextColor};
    background-color: ${({ fieldBackgroundColor }) => fieldBackgroundColor};
    border: ${({ fieldBorderColor, fieldBorderWidth }) =>
      `${fieldBorderWidth}px solid ${fieldBorderColor}`};
    border-radius: ${({ fieldBorderRadius }) => `${fieldBorderRadius}px`};
    padding-left: 2%;
    font-size: ${({ fieldFontSize }) => fieldFontSize}px;
  }
  input::placeholder,
  select::placeholder {
    color: ${({ fieldLabelColor }) => fieldLabelColor};
  }
`;

const Input = styled.input`
  outline: none;
  width: 100%;
  height: 100%;
  max-height: 65px;
  margin-bottom: 12px;
  &:active,
  &:focus {
    outline: none;
  }
  border: ${({ hasError }) => (hasError ? '2px solid #f21414 !important' : 'inherit')};
`;

const PhoneInput = styled(IMaskInput)`
  outline: none;
  width: 100%;
  height: 10%;
  max-height: 65px;
  margin-bottom: 12px;
  &:active,
  &:focus {
    outline: none;
  }
  border: ${({ haserror }) => (haserror ? '2px solid #f21414 !important' : 'inherit')};
`;

const Select = styled.select`
  outline: none;
  width: 100%;
  height: 100%;
  max-height: 65px;
  margin-bottom: 12px;
  &:active,
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: move;
  }
`;

const Option = styled.option``;

const Button = styled<any>(motion.div)`
  width: 100%;
  min-height: 25px;
  height: 100%;
  max-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  background-color: ${({ background }) => background};
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .grid-loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

type Props = {
  data: FormDataType;
  elementWidth: number;
  elementHeight: number;
};

type FormField = {
  id: string;
  label: string;
  type: string;
  value: string;
  options?: Array<string>;
};

const Form = ({ data, elementWidth, elementHeight }: Props) => {
  const { availableFields, submitForm } = useContext(ProjectContext);

  const getFieldFontSize = () => {
    const maxFontSize = 20;
    let fs = elementHeight / 4;
    if (fs >= maxFontSize) {
      fs = maxFontSize;
    }
    return fs;
  };

  const buttonFitText = useFitText({ maxFontSize: 175 });

  const [fieldFontSize, setFieldFontSize] = useState(getFieldFontSize());

  useEffect(() => {
    setFieldFontSize(getFieldFontSize());
  }, [data, elementWidth, elementHeight]);

  const [fields, setFields] = useState<Array<FormField>>([]);
  const [errors, setErrors] = useState<Array<{ id: string; error: string }>>([]);

  useEffect(() => {
    setFields(availableFields.filter((field) => data.fields.some((f) => f.id === field.id)));
  }, []);

  const [submitting, setSubmitting] = useState(false);

  const getError = (id: string) => errors.find((e) => e.id === id);

  const validate = () => {
    const errs = [];
    fields.forEach((field) => {
      if (field.value === '') {
        errs.push({
          id: field.id,
          error: 'This field cannot be left empty'
        });
      }
      if (field.type === 'email') {
        if (
          !String(field.value)
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          errs.push({
            id: field.id,
            error: 'Must be a valid email'
          });
        }
      }
    });
    setErrors(errs);
    return errs;
  };

  const onFieldChange = (e) => {
    const updatedFields = fields.map((field) => {
      const updatedField = { ...field };
      if (updatedField.id === e.target.name) {
        updatedField.value = e.target.value;
      }
      return updatedField;
    });
    setFields(updatedFields);
  };

  const onSubmit = () => {
    const errs = validate();
    if (!errs.length) {
      const fieldsToSubmit = fields.map((field) => ({
        id: field.id,
        value: field.value
      }));
      submitForm(fieldsToSubmit);
      setSubmitting(true);
    }
  };

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  };

  const transition = { duration: 1, type: 'tween' };

  const renderFields = () =>
    fields.map((field) => {
      if (field.type === 'customSelect') {
        return (
          <Select
            key={field.id}
            hasError={getError(field.id)}
            name={field.id}
            onChange={onFieldChange}
            defaultValue={field.value}
          >
            <option value="" disabled hidden>
              {field.label}
            </option>
            {field.options?.map((option) => (
              <Option key={option}>{option}</Option>
            ))}
          </Select>
        );
      }
      if (field.type === 'phone') {
        return (
          <PhoneInput
            name={field.id}
            key={field.id}
            haserror={getError(field.id)}
            placeholder={field.label}
            onAccept={(value: string) => {
              onFieldChange({ target: { value, id, name } });
            }}
            mask="(000) 000-0000"
            value={field.value}
          />
        );
      }
      if (field.type === 'email') {
        return (
          <Input
            key={field.id}
            hasError={getError(field.id)}
            onChange={onFieldChange}
            type="email"
            name={field.id}
            placeholder={field.label}
            value={field.value}
          />
        );
      }
      return (
        <Input
          key={field.id}
          hasError={getError(field.id)}
          onChange={onFieldChange}
          type="text"
          name={field.id}
          placeholder={field.label}
          value={field.value}
        />
      );
    });

  const renderButton = () => (
    <Button
      ref={buttonFitText.ref}
      onClick={onSubmit}
      background={data.color.button.background}
      color={data.color.button.text}
      style={{
        borderRadius: `${data.sizing.button.borderRadius}px`,
        border: `${data.sizing.button.borderWidth}px solid ${data.color.button.border}`,
        fontSize: buttonFitText.fontSize
      }}
    >
      {submitting ? (
        <Grid color={data.color.button.text} height={40} width={40} wrapperClass="grid-loader" />
      ) : (
        data.button.text
      )}
    </Button>
  );

  return (
    <Container
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={transition}
      fieldBackgroundColor={data.color.field.background}
      fieldTextColor={data.color.field.text}
      fieldLabelColor={data.color.field.label}
      fieldBorderColor={data.color.field.border}
      fieldBorderWidth={data.sizing.field.borderWidth}
      fieldBorderRadius={data.sizing.field.borderRadius}
      fieldFontSize={fieldFontSize}
      style={{ fontFamily: data.font }}
    >
      {renderFields()}
      {renderButton()}
    </Container>
  );
};

export default Form;
