import React from 'react';
import styled, { useTheme } from 'styled-components';
import { FiPlayCircle, FiPauseCircle, FiArrowLeftCircle } from 'react-icons/fi';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { Theme } from 'src/types';

const PlayPauseButton = styled.div`
  position: absolute;
  z-index: 1000;
  font-size: 36px;
  top: 8px;
  right: 8px;
`;

const BackButton = styled.div`
  position: absolute;
  z-index: 1000;
  font-size: 36px;
  top: 8px;
  left: 8px;
`;

const PlayIcon = styled(FiPlayCircle)`
  color: ${({ theme }) => theme.colors.controls};
  cursor: pointer;
  filter: drop-shadow(0 0 20px ${({ theme }) => theme.colors.controls};);
`;

const PauseIcon = styled(FiPauseCircle)`
  color: ${({ theme }) => theme.colors.controls};
  cursor: pointer;
  filter: drop-shadow(0 0 20px ${({ theme }) => theme.colors.controls};);
`;

const BackIcon = styled(FiArrowLeftCircle)`
  color: ${({ theme }) => theme.colors.controls};
  cursor: pointer;
  filter: drop-shadow(0 0 20px ${({ theme }) => theme.colors.controls};);
`;

type Props = {
  isBuffering: boolean;
  isPlaying: boolean;
  handlePlay: () => void;
  handlePause: () => void;
  handleBack?: (uri: string) => void;
};

const Controls = ({ isBuffering, isPlaying, handlePlay, handlePause, handleBack }: Props) => {
  const theme = useTheme() as Theme;
  return (
    <>
      {handleBack && (
        <BackButton>
          <BackIcon onClick={handleBack as any} />
        </BackButton>
      )}
      {/*<PlayPauseButton>*/}
      {/*  {isBuffering ? (*/}
      {/*    <UseAnimations*/}
      {/*      animation={loading}*/}
      {/*      size={42}*/}
      {/*      strokeColor={theme.colors.controls}*/}
      {/*      loop*/}
      {/*      speed={1}*/}
      {/*    />*/}
      {/*  ) : (*/}
      {/*    <>{isPlaying ? <PauseIcon onClick={handlePause} /> : <PlayIcon onClick={handlePlay} />}</>*/}
      {/*  )}*/}
      {/*</PlayPauseButton>*/}
    </>
  );
};

export default Controls;
