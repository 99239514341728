import { f as _objectWithoutProperties } from './_rollupPluginBabelHelpers-089ae9b3.js';
import React from 'react';
import IMaskMixin from './mixin.js';
import 'prop-types';
import 'imask/esm/imask';

var _excluded = ["inputRef"];
var IMaskInputClass = IMaskMixin(function (_ref) {
  var inputRef = _ref.inputRef,
      props = _objectWithoutProperties(_ref, _excluded);

  return React.createElement('input', Object.assign({}, props, {
    ref: inputRef
  }));
});

var IMaskInputFn = function IMaskInputFn(props, ref) {
  return React.createElement(IMaskInputClass, Object.assign({}, props, {
    ref: ref
  }));
};

var IMaskInput = React.forwardRef(IMaskInputFn);

export { IMaskInput as default };
