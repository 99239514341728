import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import React from 'react';

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProjectLoader = () => (
  <Container>
    <Oval color="#33475B" secondaryColor="#f6f6f6" height={125} width={125} />
  </Container>
);

export default ProjectLoader;
