import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Splash as SplashType, Scene } from '../types';
import Player from './Player';
import Splash from './Splash';
import { ProjectContext } from 'src/ProjectContext';

const Container = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

type Props = {
  splash: SplashType;
  activeScene: Scene | null;
  scenes: Array<Scene>;
  onElapsed: (elapsed: number) => void;
};

const Scenes = ({ splash, activeScene, scenes, onElapsed }: Props) => {
  const [shouldFillSplash, setShouldFillSplash] = useState<boolean | null>(null);
  const [splashSurface, setSplashSurface] = useState<{
    width: number | null;
    height: number | null;
  }>({
    width: null,
    height: null
  });

  const { changeScene, goBack, history, offset } = useContext(ProjectContext);

  let paths: Array<string> = [];
  if (activeScene) {
    paths = activeScene.paths;
  } else {
    paths = splash.paths;
  }

  const possibleScenes = scenes.filter((scene) => paths.some((path) => path === scene.slug));

  let previousScene: Scene | undefined;
  if (history.length > 1) {
    previousScene = scenes.find((scene) => scene.slug === history[history.length - 2]);
  }

  const dedupedScenes = Array.from(
    new Set([previousScene, activeScene, ...possibleScenes].filter((scene) => !!scene))
  );

  return (
    <>
      {dedupedScenes.map((scene) => (
        <Container
          style={{
            visibility: scene?.slug === activeScene?.slug ? 'visible' : 'hidden'
          }}
          key={scene?.slug}
        >
          {scene?.video && (
            <Player
              videoUrl={`https://stream.mux.com/${scene?.video?.playbackId}.m3u8`}
              elements={scene.elements}
              isActive={activeScene?.slug === scene.slug}
              onElapsed={onElapsed}
              onEnd={{
                behavior: scene.endBehavior as string,
                uri: scene.autoplayUri || ''
              }}
              onFillable={
                splash.paths[0] === scene.slug
                  ? (shouldFill) => setShouldFillSplash(shouldFill)
                  : () => {}
              }
              onMediaSize={
                splash.paths[0] === scene.slug
                  ? (width, height) => setSplashSurface({ width, height })
                  : () => {}
              }
              onBack={history.length > 1 ? goBack : undefined}
              offset={offset}
            />
          )}
        </Container>
      ))}
      <Container style={{ visibility: !activeScene ? 'visible' : 'hidden' }}>
        <Splash
          active={!activeScene}
          heading={splash.heading.enabled ? splash.heading.text : null}
          subheading={splash.subheading.enabled ? splash.subheading.text : null}
          textAlignment="center"
          surfaceSize={splashSurface}
          shouldFill={shouldFillSplash}
          url={`https://stream.mux.com/${dedupedScenes[0]?.video?.playbackId}.m3u8`}
          onPlay={() => {
            changeScene(dedupedScenes[0]?.slug);
          }}
        />
      </Container>
    </>
  );
};

export default Scenes;
