// @ts-nocheck
import React, { useState } from 'react';
import styled from 'styled-components';
import { Element as ElementType } from '../types';
import Controls from './Controls';
import Element from './Element';
import PlayPauseAnimation from 'src/components/PlayPauseAnimation';

const Container = styled.div<{ height: number; width: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  z-index: 1;
  overflow: hidden;
`;

type Props = {
  isBuffering: boolean;
  isPlaying: boolean;
  handlePlay: () => void;
  handlePause: () => void;
  handleBack?: (uri: string) => void;
  elapsed: number;
  elements: Array<ElementType>;
  height: number;
  width: number;
};

const Elements = ({
  isBuffering,
  isPlaying,
  handlePlay,
  handlePause,
  handleBack,
  elapsed,
  elements,
  height,
  width
}: Props) => {
  const [timers, setTimers] = useState<Array<number>>([]);
  const [playPauseAnimating, setPlayPauseAnimating] = useState(false);
  const [clickedPlayPause, setClickedPlayPause] = useState(false);
  return (
    <Container height={height} width={width}>
      <Controls
        isBuffering={isBuffering}
        isPlaying={isPlaying}
        handlePlay={handlePlay}
        handlePause={handlePause}
        handleBack={handleBack}
      />
      {elements.map((element) => (
        <Element
          key={element.id}
          elapsed={elapsed}
          element={element}
          container={{ height, width }}
        />
      ))}
      <PlayPauseAnimation
        visible={clickedPlayPause}
        animating={playPauseAnimating}
        playing={isPlaying}
        height={height}
        width={width}
        id="playPause"
        onClick={(event) => {
          if (event.target.id === 'playPause') {
            setPlayPauseAnimating(true);
            setClickedPlayPause(true);
            const playPauseTimer = setTimeout(() => {
              setPlayPauseAnimating(false);
            }, 500);
            const clickedTimer = setTimeout(() => {
              setClickedPlayPause(false);
            }, 1000);
            timers.forEach((timer) => timer && clearTimeout(timer));
            setTimers([playPauseTimer, clickedTimer]);
            if (isPlaying) {
              handlePause();
            } else {
              handlePlay();
            }
          }
        }}
      />
    </Container>
  );
};

export default Elements;
