import { assign, createMachine } from 'xstate';
import axios from 'axios';
import camelcase from 'camelcase';
import slugify from 'slugify';
import { Project, Scene, Splash, Theme } from '../types';

type Field = {
  id: string;
  type: string;
  label: string;
  value: string;
  options?: Array<string>;
};

export type Context = {
  urlData: {
    username: string;
    projectSlug: string;
    sceneSlug: string | null;
    params: Array<any>;
  };
  activeScene: Scene | null;
  offset: number;
  theme: Theme;
  splash: Splash;
  scenes: Array<Scene>;
  fields: Array<Field>;
  history: Array<string>;
};

export type Event =
  | {
      type: 'done.invoke.playerMachine.initializing:invocation[0]';
      data: { project: Project };
    }
  | { type: 'CHANGE_SCENE'; uri: string; offset?: number }
  | { type: 'GO_BACK' }
  | { type: 'FOLLOW_LINK'; url: string }
  | { type: 'SUBMIT_FORM'; fields: Array<{ id: string; value: string }> }
  | {
      type: 'done.invoke.playerMachine.initialized.submitting:invocation[0]';
    }
  | {
      type: 'error.platform.playerMachine.initialized.submitting:invocation[0]';
    };

const machine = createMachine<Context, Event>(
  {
    id: 'playerMachine',
    initial: 'initializing',
    context: {
      urlData: {
        username: '',
        projectSlug: '',
        sceneSlug: '',
        params: []
      },
      activeScene: null,
      offset: 0,
      theme: {
        colors: {
          background: '',
          overlays: '',
          controls: '',
          splashText: ''
        },
        breakpoints: {
          tablet: '768px',
          mobile: {
            large: '425px',
            medium: '375px',
            small: '320px'
          }
        },
        spacing: {
          xl: '64px',
          lg: '32px',
          md: '16px',
          sm: '8px',
          xs: '4px'
        },
        font: ''
      },
      splash: {
        heading: {
          enabled: false,
          text: ''
        },
        subheading: {
          enabled: false,
          text: ''
        },
        swipeUpCta: {
          enabled: false,
          text: '',
          link: {
            type: '',
            uri: ''
          }
        },
        paths: []
      },
      scenes: [],
      fields: [],
      history: []
    },
    states: {
      initializing: {
        invoke: {
          src: 'loadProject',
          onDone: {
            target: 'initialized',
            actions: ['updateTheme', 'updateScenes', 'updateSplash', 'updateFields']
          },
          onError: {
            target: 'error'
          }
        }
      },
      initialized: {
        initial: 'idle',
        onEntry: ['updateFieldsFromParams'],
        on: {
          CHANGE_SCENE: {
            actions: 'setActiveScene'
          },
          GO_BACK: {
            actions: 'goBack'
          },
          FOLLOW_LINK: {
            actions: 'followLink'
          }
        },
        states: {
          idle: {
            on: {
              SUBMIT_FORM: {
                actions: 'updateFieldsFromSubmit',
                target: 'submitting'
              }
            }
          },
          submitting: {
            invoke: {
              src: 'submitForm',
              onDone: 'idle',
              onError: 'idle'
            },
            onExit: 'followFormUri'
          }
        }
      },
      error: {}
    }
  },
  {
    actions: {
      updateTheme: assign<Context, Event>({
        theme: (context, event) => {
          if (event.type === 'done.invoke.playerMachine.initializing:invocation[0]') {
            return {
              ...context.theme,
              ...event.data.project?.theme,
              font: event.data.project?.settings?.font
            };
          }
          return context.theme;
        }
      }),
      updateSplash: assign<Context, Event>({
        splash: (context, event) => {
          if (event.type === 'done.invoke.playerMachine.initializing:invocation[0]') {
            return {
              ...context.splash,
              ...event.data.project?.splash
            };
          }
          return context.splash;
        }
      }),
      updateScenes: assign<Context, Event>({
        scenes: (context, event) => {
          if (event.type === 'done.invoke.playerMachine.initializing:invocation[0]') {
            return event.data.project?.scenes || [];
          }
          return context.scenes;
        }
      }),
      updateFields: assign<Context, Event>({
        fields: (context, event) => {
          const fields: Array<Field> = [];
          if (event.type === 'done.invoke.playerMachine.initializing:invocation[0]') {
            event.data.project?.scenes &&
              event.data.project?.scenes.forEach((scene) => {
                scene.elements.forEach((element) => {
                  if (element.type === 'form') {
                    element.formData?.fields.forEach((field) => {
                      if (!fields.some((f) => f.id === field.id)) {
                        fields.push({
                          ...field,
                          value: ''
                        });
                      }
                    });
                  }
                });
              });
          }
          return fields;
        }
      }),
      updateFieldsFromParams: assign<Context, Event>({
        fields: (context) =>
          context.fields.map((field) => {
            const updatedField = field;
            context.urlData.params.forEach((param) => {
              if (param.key === camelcase(slugify(field.label))) {
                updatedField.value = param.value;
              }
            });
            return updatedField;
          })
      }),
      updateFieldsFromSubmit: assign<Context, Event>({
        fields: (context, event) => {
          if (event.type === 'SUBMIT_FORM') {
            return context.fields.map((field) => {
              let updatedField = field;
              event.fields.forEach((f) => {
                if (f.id === field.id) {
                  updatedField = {
                    ...field,
                    ...f
                  };
                }
              });
              return updatedField;
            });
          }
          return context.fields;
        }
      }),
      setActiveScene: assign<Context, Event>({
        activeScene: (context, event) => {
          if (event.type === 'CHANGE_SCENE') {
            if (context.scenes.length) {
              const filteredScenes = context.scenes.filter((scene) => scene.slug === event.uri);
              if (filteredScenes.length) {
                return filteredScenes[0];
              }
            }
          }
          return null;
        },
        offset: (_, event) => {
          if (event.type === 'CHANGE_SCENE') {
            if (event.offset) {
              return event.offset;
            }
          }
          return 0;
        },
        history: (context, event) => {
          if (event.type === 'CHANGE_SCENE') {
            return [...context.history, event.uri];
          }
          return context.history;
        }
      }),
      goBack: assign<Context, Event>({
        activeScene: (context, event) => {
          if (event.type === 'GO_BACK') {
            if (context.scenes.length && context.history.length > 1) {
              const lastUri = context.history[context.history.length - 2];
              const filteredScenes = context.scenes.filter((scene) => scene.slug === lastUri);
              if (filteredScenes.length) {
                return filteredScenes[0];
              }
            }
          }
          return null;
        },
        offset: (_, event) => {
          return 0;
        },
        history: (context, event) => {
          if (event.type === 'GO_BACK') {
            return context.history.slice(0, -1);
          }
          return context.history;
        }
      }),
      followLink: (_, event) => {
        if (event?.type === 'FOLLOW_LINK') {
          setTimeout(() => {
            window.location.assign(event.url);
          }, 1000);
        }
      },
      followFormUri: assign<Context, Event>({
        activeScene: (context, event) => {
          if (
            event.type === 'error.platform.playerMachine.initialized.submitting:invocation[0]' ||
            event.type === 'done.invoke.playerMachine.initialized.submitting:invocation[0]'
          ) {
            const element = context.activeScene?.elements.find((el) => el.type === 'form');
            if (element?.formData?.button.link.type === 'internal') {
              return (
                context.scenes.find((scene) => scene.slug === element?.formData?.button.link.uri) ||
                context.activeScene
              );
            }
            window.location.assign(element?.formData?.button.link.uri || 'https://riframe.io');
          }
          return context.activeScene;
        }
      })
    },
    guards: {},
    services: {
      loadProject: async (context) => {
        const response = await axios.get(
          `${process.env.API_URL}/projects/player/${context.urlData.username}/${context.urlData.projectSlug}`
        );
        return response.data;
      },
      submitForm: async (context, event) => {
        if (event.type === 'SUBMIT_FORM') {
          const fields = context.fields.filter((field) =>
            event.fields.some((f) => f.id === field.id)
          );
          const response = await axios.post(`${process.env.API_URL}/data/form`, {
            username: context.urlData.username,
            projectSlug: context.urlData.projectSlug,
            sceneSlug: context.activeScene?.slug,
            fields
          });
          return response.data;
        }
      }
    }
  }
);

export default machine;
