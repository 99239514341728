import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import { inspect } from '@xstate/inspect';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Project from './components/Project';

const GlobalStyle = createGlobalStyle`        
    /* Box sizing rules */
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    /* Remove default margin */
    body,
    h1,
    h2,
    h3,
    h4,
    p,
    figure,
    blockquote,
    dl,
    dd {
      margin: 0;
    }

    /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
    ul[role='list'],
    ol[role='list'] {
      list-style: none;
    }
    

    /* Set core root defaults */
    html:focus-within {
      scroll-behavior: smooth;
    }

    /* Set core body defaults */
    body {
      text-rendering: optimizeSpeed;
      line-height: 1.5;
      font-family: sans-serif;
    }

    /* A elements that don't have a class get default styles */
    a:not([class]) {
      text-decoration-skip-ink: auto;
    }

    /* Make images easier to work with */
    img,
    picture {
      max-width: 100%;
      display: block;
    }

    /* Inherit fonts for inputs and buttons */
    input,
    button,
    textarea,
    select {
      font: inherit;
    }

    /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
    @media (prefers-reduced-motion: reduce) {
      html:focus-within {
        scroll-behavior: auto;
      }

      *,
      *::before,
      *::after {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
      }
    }
  `;

if (process.env.USE_INSPECTOR === 'true') {
  inspect({
    iframe: false // open in new window
  });
}

const Redirect = () => {
  useEffect(() => {
    window.location.href = 'http://riframe.io/';
  }, []);
  return null;
};

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/:username/:projectSlug/:sceneSlug?" element={<Project />} />
          <Route path="*" element={<Redirect />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
