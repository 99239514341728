import React from 'react';
import styled, { useTheme } from 'styled-components';
import { HiPlay, HiPause } from 'react-icons/hi2';
import { motion } from 'framer-motion';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const IconContainer = styled(motion.div)`
  svg {
    color: ${({ theme }) => theme.colors.controls};
  }
`;

type Props = {
  id: string;
  onClick: () => void;
  visible: boolean;
  animating: boolean;
  playing: boolean;
  height: number;
  width: number;
};

const PlayPauseAnimation = ({ id, onClick, visible, animating, playing, height, width }: Props) => {
  const variants = {
    visible: { opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0.3 }
  };
  return (
    <Container style={{ height, width }} id={id} onClick={onClick}>
      {visible && (
        <IconContainer
          initial={animating ? 'hidden' : 'visible'}
          animate={animating ? 'visible' : 'hidden'}
          variants={variants}
        >
          {playing ? <HiPlay size={height * 0.15} /> : <HiPause size={height * 0.15} />}
        </IconContainer>
      )}
    </Container>
  );
};

export default PlayPauseAnimation;
