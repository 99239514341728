import { useContext } from 'react';
import camelcase from 'camelcase';
import slugify from 'slugify';
import { ProjectContext } from '../ProjectContext';

const useDynamicText = () => (text: string) => {
  // eslint-disable-next-line
  const { params, availableFields } = useContext(ProjectContext);
  let registry: Array<{ key: string; value: string }> = [...params];
  availableFields.forEach((field: any) => {
    if (field.value) {
      registry.push({ key: field.type, value: field.value });
    }
  });
  registry = registry.map((item) => {
    return {
      ...item,
      key: `{{${item.key}}}`
    };
  });
  registry.forEach((item) => {
    text = text.replace(item.key, item.value);
  });
  return text;
};

export default useDynamicText;
