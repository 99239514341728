// @ts-nocheck

import { useMachine } from '@xstate/react';
import { useParams, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GoogleFontLoader from './GoogleFontLoader';
import { ProjectContext } from '../ProjectContext';
import machine from '../xstate/machine';
import ProjectLoader from './ProjectLoader';
import Scenes from './Scenes';
import { Session } from 'player';
import { useEffect, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import axios from 'axios';

const Project = () => {
  const { username, projectSlug, sceneSlug } = useParams<{
    username: string;
    projectSlug: string;
    sceneSlug: string;
  }>();

  const { search } = useLocation();

  const params: Array<any> = [];

  const searchParams = new URLSearchParams(search);

  searchParams.forEach((value, key) => {
    params.push({
      key,
      value
    });
  });

  const [state, send] = useMachine(machine, {
    context: {
      urlData: {
        username,
        projectSlug,
        sceneSlug: sceneSlug || null,
        params
      }
    },
    devTools: true
  });

  const contactId = searchParams.get('contactId') || undefined;

  const [session, setSession] = useState<Session>({
    sessionId: uuidv1(),
    contactId,
    projectSlug,
    journey: []
  });

  async function transmit(payload: Session) {
    await axios.post(`${process.env.API_URL}/data/session`, {
      username: state.context.urlData.username,
      session: payload
    });
  }

  useEffect(() => {
    let updatedSession = session;
    if (state.event.type === 'CHANGE_SCENE') {
      let currentLeg = session.journey[session.journey.length - 1];

      let updatedJourney = session.journey;

      if (currentLeg) {
        currentLeg = {
          ...currentLeg,
          interaction: {
            type: 'transition',
            scene: state.event.uri
          }
        };
        updatedJourney[updatedJourney.length - 1] = currentLeg;
      }

      const newLeg = {
        // @ts-ignore
        title: state.context.scenes.find((scene) => scene.slug === state.event.uri)
          ?.title as string,
        slug: state.event.uri,
        watchTime: 0
      };

      updatedSession = { ...session, journey: [...updatedJourney, newLeg] };
    }

    if (state.event.type === 'FOLLOW_LINK') {
      let currentLeg = session.journey[session.journey.length - 1];

      let updatedJourney = session.journey;

      currentLeg = {
        ...currentLeg,
        interaction: {
          type: 'navigation',
          link: state.event.url
        }
      };

      updatedJourney[updatedJourney.length - 1] = currentLeg;

      updatedSession = {
        ...session,
        journey: [...updatedJourney]
      };
    }

    if (state.event.type === 'SUBMIT_FORM') {
      let currentLeg = session.journey[session.journey.length - 1];

      let newLeg;
      let scene;
      let link;

      // @ts-ignore
      const fields = state.context.fields.filter((field) =>
        // @ts-ignore
        state.event.fields.some((f: any) => f.id === field.id)
      );

      const element = state.context.activeScene?.elements.find((el) => el.type === 'form');
      const formLink = element?.formData?.button.link;

      if (formLink) {
        if (formLink.type === 'internal') {
          scene = formLink.uri;
          newLeg = {
            // @ts-ignore
            title: state.context.scenes.find((scene) => scene.slug === formLink.uri)
              ?.title as string,
            slug: formLink.uri,
            watchTime: 0
          };
        } else {
          link = formLink.uri;
        }
      }

      currentLeg = {
        ...currentLeg,
        interaction: {
          type: 'submission',
          fields
        }
      };

      if (link) {
        currentLeg = {
          ...currentLeg,
          interaction: {
            ...(currentLeg.interaction as any),
            link
          }
        };
      }

      if (scene) {
        currentLeg = {
          ...currentLeg,
          interaction: {
            ...(currentLeg.interaction as any),
            scene
          }
        };
      }

      let updatedJourney = session.journey;

      updatedJourney[updatedJourney.length - 1] = currentLeg;

      if (newLeg) {
        updatedJourney = [...updatedJourney, newLeg];
      }

      updatedSession = { ...session, journey: [...updatedJourney] };
    }

    if (
      ['CHANGE_SCENE', 'FOLLOW_LINK', 'SUBMIT_FORM'].some((event) => state.event.type === event)
    ) {
      setSession(updatedSession);
      transmit(updatedSession)
        .then(() => {})
        .catch(() => {});
    }
  }, [state]);

  useEffect(() => {
    const currentLeg = session.journey[session.journey.length - 1];
    if (currentLeg && currentLeg.watchTime > 1) {
      if (Math.floor(currentLeg.watchTime % 2) === 0) {
        transmit(session)
          .then(() => {})
          .catch(() => {});
      }
    }
  }, [session]);

  if (state.matches('initializing')) {
    return <ProjectLoader />;
  }

  if (state.matches('error')) {
    return <p>Video Not Found</p>;
  }

  let fonts = [state.context.theme.font];

  state.context.scenes.forEach((scene) => {
    scene.elements.forEach((element) => {
      const font = element?.buttonData?.font || element?.textData?.font || element?.formData?.font;
      fonts.push(font);
    });
  });

  return (
    <ThemeProvider theme={state.context.theme}>
      <GoogleFontLoader
        fonts={fonts.map((font) => {
          return {
            font: font,
            weights: [400, 700]
          };
        })}
      />
      <ProjectContext.Provider
        value={{
          changeScene: (uri: string, offset?: string): void => {
            send({
              type: 'CHANGE_SCENE',
              uri,
              offset
            });
          },
          goBack: (): void => {
            send({
              type: 'GO_BACK'
            });
          },
          followLink: (url: string): void => {
            send({
              type: 'FOLLOW_LINK',
              url
            });
          },
          submitForm: (fields: Array<{ id: string; value: string }>): void => {
            send({
              type: 'SUBMIT_FORM',
              fields
            });
          },
          params: state.context.urlData.params,
          availableFields: state.context.fields,
          history: state.context.history,
          offset: state.context.offset
        }}
      >
        <Scenes
          splash={state.context.splash}
          activeScene={state.context.activeScene}
          scenes={state.context.scenes}
          onElapsed={(elapsed: number) => {
            if (session.journey.length > 0) {
              const leg = session.journey[session.journey.length - 1];
              let updatedJourney = [...session.journey];
              updatedJourney[updatedJourney.length - 1] = {
                ...leg,
                watchTime: elapsed
              };
              setSession({
                ...session,
                journey: updatedJourney
              });
            }
          }}
        />
      </ProjectContext.Provider>
    </ThemeProvider>
  );
};

export default Project;
