// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FormDataType, Element as ElementType } from '../types';
import Button from './elements/Button';
import Form from './elements/Form';
import Text from './elements/Text';
import Image from './elements/Image';

const Container = styled<any>(motion.div)`
  z-index: ${({ isActive }) => (isActive ? 999 : -1)};
  position: absolute;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  height: ${({ height }) => height}%;
  width: ${({ width }) => width}%;
`;

type Props = {
  elapsed: number;
  element: ElementType;
  container: {
    height: number;
    width: number;
  };
};

const Element = ({ elapsed, element, container }: Props) => {
  const isActive = element.time.start <= elapsed && elapsed <= element.time.end + 0.1;
  const renderElement = () => {
    if (isActive) {
      if (element.type === 'text') {
        return (
          <Text key={element.id} text={element.textData?.content} color={element.textData?.color} />
        );
      }
      if (element.type === 'button') {
        return (
          <Button
            key={element.id}
            font={element.buttonData?.font}
            text={element.buttonData?.content.text}
            textSize={element.buttonData?.content?.size}
            color={element.buttonData?.color.text}
            background={element.buttonData?.color.background}
            border={element.buttonData?.border}
            elementWidth={container.width * (element.size.width / 100)}
            elementHeight={container.height * (element.size.height / 100)}
            link={element.buttonData?.link}
          />
        );
      }
      if (element.type === 'form') {
        return (
          <Form
            key={element.id}
            data={element.formData as FormDataType}
            elementWidth={container.width * (element.size.width / 100)}
            elementHeight={container.height * (element.size.height / 100)}
          />
        );
      }
      if (element.type === 'image') {
        return (
          <Image key={element.id} source={element.imageData.source} link={element.imageData.link} />
        );
      }
    }
  };
  return (
    <Container
      isActive={isActive}
      height={element.size.height}
      width={element.size.width}
      top={element.position.top}
      left={element.position.left}
      key={element.id}
    >
      {renderElement()}
    </Container>
  );
};

export default Element;
