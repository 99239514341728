// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ProjectContext } from '../../ProjectContext';
import useDynamicText from '../../hooks/useDynamicText';
import { Grid } from 'react-loader-spinner';
import useFitText from 'use-fit-text';

const Container = styled<any>(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

type Props = {
  text?: string;
  textSize?: string;
  color?: string;
  background?: string;
  elementWidth: number;
  elementHeight: number;
  link?: {
    type: string;
    uri: string;
  };
  handler?: () => void;
};

const Button = ({
  font,
  text,
  textSize,
  color,
  background,
  border,
  elementHeight,
  link,
  handler
}: Props) => {
  let maxFontSize;

  if (textSize === 'large') {
    maxFontSize = 500;
  }

  if (textSize === 'medium') {
    maxFontSize = 250;
  }

  if (textSize === 'small') {
    maxFontSize = 175;
  }

  const { fontSize, ref } = useFitText({ minFontSize: 2, maxFontSize });

  const { changeScene, followLink } = useContext(ProjectContext);
  const [redirecting, setRedirecting] = useState(false);
  const t = useDynamicText();
  // TODO implement dynamic text (hence interpolated)
  const interpolatedUrl = link.uri;
  const handleClick = () => {
    if (handler) {
      handler();
    }
    if (link) {
      if (link.type === 'external') {
        followLink(interpolatedUrl);
        setRedirecting(true);
      }
      if (link.type === 'internal') {
        changeScene(link.uri);
      }
    }
  };

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  };

  const transition = { duration: 1, type: 'tween' };

  return (
    <Container
      ref={ref}
      color={color}
      background={background}
      fontSize={fontSize}
      onClick={handleClick}
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={transition}
      style={{
        fontFamily: font,
        borderRadius: `${border.radius}px`,
        border: `${border.width}px ${border.color} solid`,
        color,
        background,
        fontSize
      }}
    >
      {redirecting ? <Grid color={color} height={elementHeight * 0.5} /> : t(text)}
    </Container>
  );
};

export default Button;
