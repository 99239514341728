// @ts-nocheck

import React, { useState, useContext, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import Elements from './Elements';
import { ProjectContext } from '../ProjectContext';
import { Element } from '../types';
import ProjectLoader from './ProjectLoader';

const Container = styled.div<{ shouldFill: boolean | null; visible: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  visibility: ${({ visible }) => (visible ? 'inherit' : 'hidden')};
  ${({ shouldFill }) => {
    if (shouldFill) {
      return `video {
    object-fit: cover !important;
  }`;
    }
  }}
`;

type Props = {
  isActive: boolean;
  videoUrl: string;
  elements: Array<Element>;
  onEnd: {
    behavior: string;
    uri: string;
  };
  onFillable: (arg: boolean) => void;
  onMediaSize: (width: number, height: number) => void;
  onElapsed: (elapsed: number) => void;
  onBack?: () => void;
  offset: number;
};

const Player = ({
  isActive,
  videoUrl,
  elements,
  onEnd,
  onFillable,
  onMediaSize,
  onElapsed,
  onBack,
  offset
}: Props) => {
  const { changeScene } = useContext(ProjectContext);
  const ref = useRef();

  const [elapsed, setElapsed] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isBuffering, setIsBuffering] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [mediaWidth, setMediaWidth] = useState(0);
  const [mediaHeight, setMediaHeight] = useState(0);
  const [shouldFill, setShouldFill] = useState<boolean | null>(null);

  const calculateVideoDimensions = (player: any) => {
    // Ratio of the video's intrisic dimensions
    const videoRatio = player.videoWidth / player.videoHeight;
    // The width and height of the video element
    let width = player.offsetWidth;
    let height = player.offsetHeight;
    // The ratio of the element's width to its height
    const elementRatio = width / height;
    // If the video element is short and wide
    if (elementRatio > videoRatio) width = height * videoRatio;
    // It must be tall and thin, or exactly equal to the original ratio
    else height = width / videoRatio;
    return {
      width,
      height
    };
  };

  const getVideoDimensions = () => {
    try {
      // @ts-ignore
      const player = ref.current.getInternalPlayer();
      return calculateVideoDimensions(player);
    } catch (e) {
      return {
        width: null,
        height: null
      };
    }
  };

  const setVideoSize = (width?: string, height?: string) => {
    if (!width && !height) {
      const dimensions = getVideoDimensions();
      width = dimensions.width;
      height = dimensions.height;
    }
    if (width && height) {
      const innerHeight = Number(window.innerHeight);
      const innerWidth = Number(window.innerWidth);
      let w = Number(width);
      let h = Number(height);
      const widthDifferential = innerWidth - w;
      const heightDifferential = innerHeight - h;

      if (height > width) {
        if (widthDifferential >= 0 && widthDifferential < 40 && heightDifferential < 30) {
          setShouldFill(true);
          w = innerWidth;
          h = innerHeight;
        } else {
          setShouldFill(false);
        }
      }

      if (height < width) {
        if (heightDifferential === 0 && widthDifferential > 0 && widthDifferential < 200) {
          setShouldFill(true);
          w = innerWidth;
          h = innerHeight;
        } else {
          setShouldFill(false);
        }
      }

      setMediaWidth(w);
      setMediaHeight(h);
    }
  };

  const handleVideoSize = () => {
    setVideoSize();
  };

  useEffect(() => {
    window.addEventListener('resize', handleVideoSize);
    const interval = setInterval(() => {
      const { width, height } = getVideoDimensions();
      if (width && height) {
        clearInterval(interval);
        setVideoSize();
        setIsReady(true);
      }
    }, 500);
    return () => {
      window.removeEventListener('resize', handleVideoSize);
    };
  }, []);

  const handleProgress = (progress: any) => {
    setElapsed(progress.playedSeconds);
    onElapsed(progress.playedSeconds);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleEnd = () => {
    if (onEnd.behavior === 'autoplay') {
      changeScene(onEnd.uri);
    } else {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (shouldFill !== null) {
      onFillable(shouldFill);
    }
    if (mediaWidth && mediaHeight) {
      onMediaSize(mediaWidth, mediaHeight);
    }
  }, [shouldFill, mediaHeight, mediaWidth]);

  // useEffect(() => {
  //   if (isActive) {
  //     // @ts-ignore
  //     // ref.current.seekTo(0, 'seconds');
  //   }
  // }, [isActive]);

  return (
    <>
      {isActive && !isReady && <ProjectLoader />}
      <Container shouldFill={shouldFill} visible={isActive && isReady}>
        {isActive && (
          <Elements
            isBuffering={isBuffering || !isReady}
            isPlaying={isPlaying}
            handlePlay={handlePlay}
            handlePause={handlePause}
            elapsed={elapsed}
            elements={elements}
            height={mediaHeight}
            width={mediaWidth}
            handleBack={onBack}
          />
        )}
        <ReactPlayer
          ref={ref as any}
          url={videoUrl}
          width="100%"
          height="100%"
          className="player"
          playing={isActive && isPlaying}
          playsinline
          progressInterval={1000}
          onProgress={handleProgress}
          onBuffer={() => setIsBuffering(true)}
          onBufferEnd={() => setIsBuffering(false)}
          onEnded={handleEnd}
        />
      </Container>
    </>
  );
};

export default Player;
