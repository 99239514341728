// @ts-nocheck
import React, { useContext } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ProjectContext } from '../../ProjectContext';

const Container = styled<any>(motion.div)`
  height: 100%;
  width: 100%;
  background-image: url(${({ source }) => source});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

type Props = {
  source: string;
  link?: {
    type: string;
    uri: string;
  };
  handler?: () => void;
};

const Button = ({ source, link, handler }: Props) => {
  const { changeScene } = useContext(ProjectContext);

  const handleClick = () => {
    if (handler) {
      handler();
    }

    if (link) {
      if (link.type === 'external') {
        window.location.assign(link.uri);
      }
      if (link.type === 'internal') {
        changeScene(link.uri);
      }
    }
  };

  return <Container source={source} onClick={handleClick} />;
};

export default Button;
