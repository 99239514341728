import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FiPlayCircle } from 'react-icons/fi';
import { motion } from 'framer-motion';
import { ProjectContext } from '../ProjectContext';
import useDynamicText from '../hooks/useDynamicText';
import ProjectLoader from './ProjectLoader';
import ReactPlayer from 'react-player';
import useFitText from 'use-fit-text';

const Container = styled.div<{ shouldFill: boolean | null }>`
  position: relative;
  height: 100%;
  width: 100%;
  ${({ shouldFill }) => {
    if (shouldFill) {
      return `video {
    object-fit: cover !important;
  }`;
    }
  }}
`;

const Overlay = styled.div<{ width: number | null; height: number | null }>`
  background: ${({ theme }) => theme.colors.overlays}B3;
  overflow: hidden;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  ${({ width, height }) => {
    if (width && height) {
      return `
       width: ${width}px;
       height: ${height}px;
       position: absolute;
       top: 0;
       bottom: 0;
       left: 0;
       right: 0;
       margin: auto;
      `;
    }
  }}
`;

const TopArea = styled.div`
  height: 25%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
`;

const Headings = styled.div`
  padding: 16px;
  color: ${({ theme }) => theme.colors.splashText};
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
`;

const H = styled.h1`
  font-family: ${({ theme }) => theme.font};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-flow: column;
  line-height: 1.2;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const H1 = styled(H)`
  height: 60%;
  font-weight: 700;
`;

const H3 = styled(H)`
  margin-top: -1%;
  height: 40%;
  font-weight: 400;
`;

const PlayButtonContainer = styled<any>(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ size }) => size}px;
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
`;

const PlayButton = styled(FiPlayCircle)`
  color: ${({ theme }) => theme.colors.controls};
`;

type Props = {
  heading: string | null;
  subheading: string | null;
  textAlignment: string;
  buttons?: any;
  surfaceSize: {
    width: number | null;
    height: number | null;
  };
  shouldFill: boolean | null;
  active: boolean;
  url: string;
  onPlay: () => void;
};

const Heading: React.FC<{ children: string }> = ({ children }) => {
  const { fontSize, ref } = useFitText({ maxFontSize: 500 });
  return (
    <H1 style={{ fontSize }} ref={ref}>
      {children}
    </H1>
  );
};

const SubHeading: React.FC<{ children: string }> = ({ children }) => {
  const { fontSize, ref } = useFitText({ maxFontSize: 300 });
  return (
    <H3 style={{ fontSize }} ref={ref}>
      {children}
    </H3>
  );
};

const Player: React.FC<{ url: string; active: boolean }> = ({ url, active }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100);
  }, [url]);
  if (show) {
    return (
      <ReactPlayer
        ref={ref as any}
        url={url}
        width="100%"
        height="100%"
        className="player"
        muted
        playsinline
        playing={active}
        progressInterval={1000}
        onProgress={(progress) => {
          if (progress.playedSeconds > 10) {
            //@ts-ignore
            ref?.current?.seekTo(0);
          }
        }}
      />
    );
  }
  return null;
};

const Splash = ({
  active,
  heading,
  subheading,
  buttons,
  surfaceSize,
  shouldFill,
  url,
  onPlay
}: Props) => {
  const t = useDynamicText();

  if (surfaceSize.width && surfaceSize.height) {
    return (
      <Container shouldFill={shouldFill}>
        <Overlay width={surfaceSize.width} height={surfaceSize.height}>
          {heading || subheading || buttons ? (
            <TopArea>
              {heading || subheading ? (
                <Headings>
                  {heading && <Heading>{t(heading)}</Heading>}
                  {subheading && <SubHeading>{t(subheading)}</SubHeading>}
                </Headings>
              ) : null}
            </TopArea>
          ) : null}

          <PlayButtonContainer
            size={surfaceSize.height / 5}
            animate={{
              scale: [1, 1.2, 1]
            }}
            transition={{
              duration: 6,
              ease: 'easeInOut',
              loop: Infinity
            }}
            onClick={onPlay}
          >
            <PlayButton />
          </PlayButtonContainer>
        </Overlay>
        <Player url={url} active={active} />
      </Container>
    );
  }
  return <ProjectLoader />;
};

export default Splash;
